import Input from "../../atoms/input";
import styled from "styled-components";
import {ChangeEvent, useCallback, useEffect, useState} from "react";
import LayoutRightTemplate from "../layout/right";
import {useNavigate, useParams} from "react-router-dom";
import {useFindRegister, useUpdateRegister} from "../../../api/register";
import {levelItems, roleItems, typeItems} from "../../../constants";
import Select from "../../atoms/select";
import InputPreviewFile from "../../molecules/inputPreviewFile";

export const EditRegisterTemplate = () => {
    const {seq} = useParams();
    const navigate = useNavigate();
    const {data} = useFindRegister({seq});
    const {mutate, isLoading} = useUpdateRegister();
    const [info, setInfo] = useState({
        seq,
        profileUrl: '',
        type: 1,
        level: 1,
        country: '',
        email: ''
    });

    const onChangeInfo = useCallback((e?: ChangeEvent<HTMLInputElement>) => {
        const name = e?.target.name as string;
        const value = e?.target.value as string | number;

        setInfo((prev) => ({
            ...prev,
            [name]: value,
        }));
    }, []);

    const onSubmit = useCallback(() => {
        if (!info.profileUrl || !info.country || !info.email) {
            alert('필수 값을 입력해주세요.');
            return false;
        }

        mutate(info, {
            onSuccess: async () => {
                navigate('/register');
            },
        });
    }, [info]);

    useEffect(() => {
        if (data) {
            setInfo({
                seq: data?.seq,
                profileUrl: data?.profileUrl,
                type: data?.type,
                level: data?.level,
                country: data?.country,
                email: data?.email
            });
        }
    }, [data]);

    return (
        <LayoutRightTemplate title={'등록 신청 수정'} clickTitle={'등록'} onClick={onSubmit} isLoading={isLoading}>
            <FormWrap>
                <span> 프로필 Url </span>
                <Input type={"text"} value={info.profileUrl} name={'profileUrl'} onChange={onChangeInfo}/>

                <span> 타입 </span>
                <Select items={typeItems} essential={true} value={info.type} name={'type'} onChange={onChangeInfo}/>

                <span> 레벨 </span>
                <Select items={levelItems} essential={true} value={info.level} name={'level'} onChange={onChangeInfo}/>

                <span> 국가 </span>
                <Input type={"text"} value={info.country} name={'country'} onChange={onChangeInfo}/>

                <span> 이메일 </span>
                <Input type={"text"} value={info.email} name={'email'} onChange={onChangeInfo}/>
            </FormWrap>
        </LayoutRightTemplate>
    )
}

const FormWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
  gap: 10px;

  & span {
    font-weight: bold;
    margin-top: 10px;
  }
`
