import {useCallback, useMemo,} from "react";
import Table from "../../atoms/table";
import Tag from "../../atoms/tag";
import LayoutRightTemplate from "../layout/right";
import {useNavigate} from "react-router-dom";
import {contactTypes} from "../../../types/contact";
import {useDeleteContact, useFindContacts, useFindContactsAll} from "../../../api/contact";
import Action from "../../atoms/table/action";
import Pagination from "../../atoms/table/pagination";
import {useGetQueryString} from "../../../utils/useGetQueryString";
import {alertState, closeAlert, showAlert} from "../../../recoil/alert";
import {useSetRecoilState} from "recoil";
import {useQueryClient} from "react-query";
import * as xlsx from "xlsx";
import Button from "../../atoms/button";

export const ContactTemplate = () => {
    const {data: contacts, isLoading} = useFindContacts();
    const navigate = useNavigate();
    const {mutate} = useDeleteContact();
    const {mutate: findContactsAllMutate, isLoading: contactsAllLoading} = useFindContactsAll();
    const {page} = useGetQueryString();
    const setAlert = useSetRecoilState(alertState);
    const queryClient = useQueryClient();

    const goAdd = useCallback(() => {
        navigate('/contact/add');
    }, []);

    const goEdit = useCallback((seq: string) => {
        navigate(`/contact/edit/${seq}`);
    }, []);

    const onDelete = useCallback((seq: string) => {
        showAlert(setAlert, {title: '정말 삭제하시겠습니까?', onSubmit: () =>
                mutate({seq}, {
                    onSuccess: () => {
                        queryClient.invalidateQueries(['contact']);
                        closeAlert(setAlert);
                    }
                }),
        })
    }, []);

    const columns = useMemo(
        () => [
            {header: '상태', accessor: 'useYn', width: '5%'},
            {header: '이메일', accessor: 'email', width: '20%'},
            {header: '이름', accessor: 'name', width: '20%'},
            {header: '내용', accessor: 'message', width: '30%'},
            {header: '가입일', accessor: 'insertDate', width: '10%'},
            {header: '수정일', accessor: 'updateDate', width: '10%'},
            {header: '', accessor: 'action', width: '5%'},
        ], []);

    const data = useMemo(() => contacts?.data && contacts?.data.length > 0 ? contacts?.data.map((x: contactTypes) => {
        return {
            ...x,
            useYn: x.useYn === 0 ? <Tag theme={'red'}>탈퇴</Tag> : <Tag theme={'navy'}>정상</Tag>,
            insertDate: x.insertDate?.slice(0, 10),
            updateDate: x.updateDate?.slice(0, 10),
            action: Action({seq: x.seq || '', goEdit, onDelete}),
        };
    }) : '', [contacts]);

    const onDownloadExcel = useCallback(() => {
        findContactsAllMutate(undefined, {
            onSuccess: ({data}) => {
                const workbook = xlsx.utils.book_new();
                const worksheet = xlsx.utils.json_to_sheet(data);
                xlsx.utils.book_append_sheet(workbook, worksheet, 'sheet1');
                xlsx.writeFile(workbook, 'contact_list.xlsx');
            }
        })
    }, []);

    return (
        <LayoutRightTemplate title={'연락망 관리'} clickTitle={'등록'} onClick={goAdd} buttonChildren={
            <Button onClick={onDownloadExcel} $isLoading={contactsAllLoading}>
                XLSX
            </Button>
        }>
            <Table columns={columns} data={data} isLoading={isLoading}/>
            <Pagination count={contacts?.count || 1} page={Number(page)}/>
        </LayoutRightTemplate>
    );
};
