import {EditContactTemplate} from "../../components/templates/contact/edit";
import LayoutTemplate from "../../components/templates/layout/left";

export const EditContact = () => {
    return (
        <LayoutTemplate>
            <EditContactTemplate />
        </LayoutTemplate>
    );
};
