import {useCallback, useMemo, useState} from "react";
import Table from "../../atoms/table";
import Tag from "../../atoms/tag";
import LayoutRightTemplate from "../layout/right";
import {useNavigate} from "react-router-dom";
import {inviteTypes} from "../../../types/invite";
import {useDeleteInvite, useFindInvites, useFindInvitesAll} from "../../../api/invite";
import Action from "../../atoms/table/action";
import Pagination from "../../atoms/table/pagination";
import {useGetQueryString} from "../../../utils/useGetQueryString";
import {alertState, closeAlert, showAlert} from "../../../recoil/alert";
import {useSetRecoilState} from "recoil";
import {useQueryClient} from "react-query";
import Button from "../../atoms/button";
import * as xlsx from "xlsx";

export const InviteTemplate = () => {
    const {data: invites, isLoading} = useFindInvites();
    const navigate = useNavigate();
    const {mutate} = useDeleteInvite();
    const {mutate: findInvitesAllMutate, isLoading: invitesAllLoading} = useFindInvitesAll();
    const {page} = useGetQueryString();
    const setAlert = useSetRecoilState(alertState);
    const queryClient = useQueryClient();

    const goAdd = useCallback(() => {
        navigate('/invite/add');
    }, []);

    const goEdit = useCallback((seq: string) => {
        navigate(`/invite/edit/${seq}`);
    }, []);

    const onDelete = useCallback((seq: string) => {
        showAlert(setAlert, {title: '정말 삭제하시겠습니까?', onSubmit: () =>
                mutate({seq}, {
                    onSuccess: () => {
                        queryClient.invalidateQueries(['invite']);
                        closeAlert(setAlert);
                    }
                }),
        })
    }, []);

    const columns = useMemo(
        () => [
            {header: '상태', accessor: 'useYn', width: '5%'},
            {header: '이메일', accessor: 'email', width: '70%'},
            {header: '가입일', accessor: 'insertDate', width: '10%'},
            {header: '수정일', accessor: 'updateDate', width: '10%'},
            {header: '', accessor: 'action', width: '5%'},
        ], []);

    const data = useMemo(() => invites?.data && invites?.data.length > 0 ? invites?.data.map((x: inviteTypes) => {
        return {
            ...x,
            useYn: x.useYn === 0 ? <Tag theme={'red'}>탈퇴</Tag> : <Tag theme={'navy'}>정상</Tag>,
            insertDate: x.insertDate?.slice(0, 10),
            updateDate: x.updateDate?.slice(0, 10),
            action: Action({seq: x.seq || '', goEdit, onDelete}),
        };
    }) : '', [invites]);

    console.log(invites);

    const onDownloadExcel = useCallback(() => {
        findInvitesAllMutate(undefined, {
            onSuccess: ({data}) => {
                const workbook = xlsx.utils.book_new();
                const worksheet = xlsx.utils.json_to_sheet(data);
                xlsx.utils.book_append_sheet(workbook, worksheet, 'sheet1');
                xlsx.writeFile(workbook, 'invite_list.xlsx');
            }
        })
    }, []);

    return (
        <LayoutRightTemplate title={'초대 관리'} clickTitle={'등록'} onClick={goAdd} buttonChildren={
            <Button onClick={onDownloadExcel} $isLoading={invitesAllLoading}>
                XLSX
            </Button>
        }>
            <Table columns={columns} data={data} isLoading={isLoading}/>
            <Pagination count={invites?.count || 1} page={Number(page)}/>
        </LayoutRightTemplate>
    );
};
