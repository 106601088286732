import styled from "styled-components";
import {ChangeEvent} from "react";
import {colors} from "../../../styles/colors";

type props = {
    value?: any;
    name?: string;
    width?: string;
    onChange?(e?: ChangeEvent<HTMLTextAreaElement>): void;
    theme?: textareaThemes;
}
export const textareaThemes = {
    normal: 'normal',
} as const;
export type textareaThemes = typeof textareaThemes[keyof typeof textareaThemes];

const Textarea = ({value, name, theme, width, onChange}: props) => {
    return (
        <ThemeTextareaWrap value={value} name={name} theme={theme} width={width} onChange={onChange} />
    )
}

const TextareaWrap = styled.textarea<props>`
  border-radius: 6px;
  padding: 10px;
  height: 200px;
  resize: none;
  ${({width}) => (width ? `width: ${width}` : '')};
`;

const ThemeTextareaWrap = styled(TextareaWrap)`
  ${({theme}) => {
    switch (theme) {
      default:
        return `
            color: ${colors.black};
            border: 1px solid #eee;
            background-color: ${colors.white};
            transition: all 0.3s;
            
            &::placeholder {
                color: #eee;
            }
            
            &:hover {
                border: 1px solid ${colors.main};
            }
  
            &:focus {
                box-shadow: 0 0 0 3px rgba(5, 145, 255, 0.1);
            }
        `;
    }
  }};
`

export default Textarea;
