import axios from 'axios';
import {useQuery, useMutation} from "react-query";
import {useCallback} from "react";
import {useGetQueryString} from "../utils/useGetQueryString";
import {inviteTypes} from "../types/invite";

export const useUpdateInvite = () => {
    const api = useCallback((data: inviteTypes) => {
        return axios.put('/admin/api/v1/invite', data);
    }, []);

    return useMutation(
        async (data: inviteTypes) => {
            const response = await api(data);
            return response.data;
        },
    );
}

export const useInsertInvite = () => {
    const api = useCallback((data: inviteTypes) => {
        return axios.post('/admin/api/v1/invite', data);
    }, []);

    return useMutation(
        async (data: inviteTypes) => {
            const response = await api(data);
            return response.data;
        },
    );
}

export const useDeleteInvite = () => {
    const api = useCallback(({seq}: Pick<inviteTypes, 'seq'>) => {
        return axios.delete(`/admin/api/v1/invite/${seq}`);
    }, []);

    return useMutation(
        async ({seq}: Pick<inviteTypes, 'seq'>) => {
            const response = await api({seq});
            return response.data.data;
        },
    );
}

export const useFindInvite = ({seq}: inviteTypes) => {
    const api = useCallback(() => {
        return axios.get(`/admin/api/v1/invite/${seq}`);
    }, []);

    return useQuery(
        ['inviteDetail'],
        async () => {
            const response = await api();
            return response.data.data;
        },
    );
}

export const useFindInvites = () => {
    const {page, search} = useGetQueryString();

    const api = useCallback(() => {
        return axios.post(`/admin/api/v1/invite/list`, {page, search});
    }, [page, search]);

    return useQuery(
        ['invite', {page, search}],
        async () => {
            const response = await api();
            return response.data;
        },
    );
}

export const useFindInvitesAll = () => {
    const api = useCallback(() => {
        return axios.post(`/admin/api/v1/invite/list/all`);
    }, []);

    return useMutation(
        async () => {
            const response = await api();
            return response.data;
        },
    );
}
