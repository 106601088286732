import axios from 'axios';
import {useQuery, useMutation} from "react-query";
import {useCallback} from "react";
import {useGetQueryString} from "../utils/useGetQueryString";
import {contactTypes} from "../types/contact";

export const useUpdateContact = () => {
    const api = useCallback((data: contactTypes) => {
        return axios.put('/admin/api/v1/contact', data);
    }, []);

    return useMutation(
        async (data: contactTypes) => {
            const response = await api(data);
            return response.data;
        },
    );
}

export const useInsertContact = () => {
    const api = useCallback((data: contactTypes) => {
        return axios.post('/admin/api/v1/contact', data);
    }, []);

    return useMutation(
        async (data: contactTypes) => {
            const response = await api(data);
            return response.data;
        },
    );
}

export const useDeleteContact = () => {
    const api = useCallback(({seq}: Pick<contactTypes, 'seq'>) => {
        return axios.delete(`/admin/api/v1/contact/${seq}`);
    }, []);

    return useMutation(
        async ({seq}: Pick<contactTypes, 'seq'>) => {
            const response = await api({seq});
            return response.data.data;
        },
    );
}

export const useFindContact = ({seq}: contactTypes) => {
    const api = useCallback(() => {
        return axios.get(`/admin/api/v1/contact/${seq}`);
    }, []);

    return useQuery(
        ['contactDetail'],
        async () => {
            const response = await api();
            return response.data.data;
        },
    );
}

export const useFindContacts = () => {
    const {page, search} = useGetQueryString();

    const api = useCallback(() => {
        return axios.post(`/admin/api/v1/contact/list`, {page, search});
    }, [page, search]);

    return useQuery(
        ['contact', {page, search}],
        async () => {
            const response = await api();
            return response.data;
        },
    );
}

export const useFindContactsAll = () => {
    const api = useCallback(() => {
        return axios.post(`/admin/api/v1/contact/list/all`);
    }, []);

    return useMutation(
        async () => {
            const response = await api();
            return response.data;
        },
    );
}
