import Input from "../../atoms/input";
import styled from "styled-components";
import {ChangeEvent, useCallback, useState} from "react";
import LayoutRightTemplate from "../layout/right";
import {useInsertUser} from "../../../api/user";
import {useNavigate} from "react-router-dom";
import {roleItems} from "../../../constants";
import Select from "../../atoms/select";

export const AddUserTemplate = () => {
    const {mutate, isLoading} = useInsertUser();
    const navigate = useNavigate();
    const [info, setInfo] = useState({
        id: '',
        name: '',
        telephone: '',
        password: '',
        role: '',
    });

    const onChangeInfo = useCallback((e?: ChangeEvent<HTMLInputElement>) => {
        const name = e?.target.name as string;
        const value = e?.target.value as string | number;

        setInfo((prev) => ({
            ...prev,
            [name]: value,
        }));
    }, []);

    const onSubmit = useCallback(() => {
        if (!info.name || !info.id || !info.password) {
            alert('필수 값을 입력해주세요.');
            return false;
        }

        mutate(info, {
            onSuccess: async () => {
                navigate('/user');
            },
        });
    }, [info]);

    return (
        <LayoutRightTemplate title={'유저 등록'} clickTitle={'등록'} onClick={onSubmit} isLoading={isLoading}>
            <FormWrap>
                <span> 아이디 </span>
                <Input type={"text"} value={info.id} name={'id'} onChange={onChangeInfo}/>

                <span> 비밀번호 </span>
                <Input type={"password"} value={info.password} name={'password'} onChange={onChangeInfo}/>

                <span> 이름 </span>
                <Input type={"text"} value={info.name} name={'name'} onChange={onChangeInfo}/>

                <span> 핸드폰 </span>
                <Input type={"number"} value={info.telephone} name={'telephone'} onChange={onChangeInfo}/>

                <span> 권한 </span>
                <Select items={roleItems} essential={true} value={info.role} name={'role'} onChange={onChangeInfo}/>
            </FormWrap>
        </LayoutRightTemplate>
    )
}

const FormWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
  gap: 10px;

  & span {
    font-weight: bold;
    margin-top: 10px;
  }
`
