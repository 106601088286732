import {AddInviteTemplate} from "../../components/templates/invite/add";
import LayoutTemplate from "../../components/templates/layout/left";

export const AddInvite = () => {
    return (
        <LayoutTemplate>
            <AddInviteTemplate />
        </LayoutTemplate>
    )
}
