import Input from "../../atoms/input";
import styled from "styled-components";
import {ChangeEvent, useCallback, useState} from "react";
import LayoutRightTemplate from "../layout/right";
import {useInsertInvite} from "../../../api/invite";
import {useNavigate} from "react-router-dom";

export const AddInviteTemplate = () => {
    const {mutate, isLoading} = useInsertInvite();
    const navigate = useNavigate();
    const [info, setInfo] = useState({
        email: '',
    });

    const onChangeInfo = useCallback((e?: ChangeEvent<HTMLInputElement>) => {
        const name = e?.target.name as string;
        const value = e?.target.value as string | number;

        setInfo((prev) => ({
            ...prev,
            [name]: value,
        }));
    }, []);

    const onSubmit = useCallback(() => {
        if (!info.email) {
            alert('필수 값을 입력해주세요.');
            return false;
        }

        mutate(info, {
            onSuccess: async () => {
                navigate('/invite');
            },
        });
    }, [info]);

    return (
        <LayoutRightTemplate title={'초대 등록'} clickTitle={'등록'} onClick={onSubmit} isLoading={isLoading}>
            <FormWrap>
                <span> 이메일 </span>
                <Input type={"text"} value={info.email} name={'email'} onChange={onChangeInfo}/>
            </FormWrap>
        </LayoutRightTemplate>
    )
}

const FormWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
  gap: 10px;

  & span {
    font-weight: bold;
    margin-top: 10px;
  }
`
