import {InviteTemplate} from "../../components/templates/invite";
import LayoutTemplate from "../../components/templates/layout/left";

export const Invite = () => {
    return (
        <LayoutTemplate>
            <InviteTemplate />
        </LayoutTemplate>
    );
};
