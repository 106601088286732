import axios from 'axios';
import {useQuery, useMutation} from "react-query";
import {useCallback} from "react";
import {useGetQueryString} from "../utils/useGetQueryString";
import {registerTypes} from "../types/register";
export const useUpdateRegister = () => {
    const api = useCallback((data: registerTypes) => {
        return axios.put('/admin/api/v1/register', data);
    }, []);

    return useMutation(
        async (data: registerTypes) => {
            const response = await api(data);
            return response.data;
        },
    );
}

export const useInsertRegister = () => {
    const api = useCallback((data: registerTypes) => {
        return axios.post('/admin/api/v1/register', data);
    }, []);

    return useMutation(
        async (data: registerTypes) => {
            const response = await api(data);
            return response.data;
        },
    );
}

export const useDeleteRegister = () => {
    const api = useCallback(({seq}: Pick<registerTypes, 'seq'>) => {
        return axios.delete(`/admin/api/v1/register/${seq}`);
    }, []);

    return useMutation(
        async ({seq}: Pick<registerTypes, 'seq'>) => {
            const response = await api({seq});
            return response.data.data;
        },
    );
}

export const useFindRegister = ({seq}: registerTypes) => {
    const api = useCallback(() => {
        return axios.get(`/admin/api/v1/register/${seq}`);
    }, []);

    return useQuery(
        ['registerDetail'],
        async () => {
            const response = await api();
            return response.data.data;
        },
    );
}

export const useFindRegisters = () => {
    const {page, search} = useGetQueryString();

    const api = useCallback(() => {
        return axios.post(`/admin/api/v1/register/list`, {page, search});
    }, [page, search]);

    return useQuery(
        ['register', {page, search}],
        async () => {
            const response = await api();
            return response.data;
        },
    );
}

export const useFindRegistersAll = () => {
    const api = useCallback(() => {
        return axios.post(`/admin/api/v1/register/list/all`);
    }, []);

    return useMutation(
        async () => {
            const response = await api();
            return response.data;
        },
    );
}
