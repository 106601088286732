import {RegisterTemplate} from "../../components/templates/register";
import LayoutTemplate from "../../components/templates/layout/left";

export const Register = () => {
    return (
        <LayoutTemplate>
            <RegisterTemplate />
        </LayoutTemplate>
    );
};
