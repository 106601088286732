import {EditRegisterTemplate} from "../../components/templates/register/edit";
import LayoutTemplate from "../../components/templates/layout/left";

export const EditRegister = () => {
    return (
        <LayoutTemplate>
            <EditRegisterTemplate />
        </LayoutTemplate>
    );
};
