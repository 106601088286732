import {useCallback, useMemo, useState} from "react";
import Table from "../../atoms/table";
import Tag from "../../atoms/tag";
import LayoutRightTemplate from "../layout/right";
import {useNavigate} from "react-router-dom";
import {userTypes} from "../../../types/user";
import {useDeleteUser, useFindUsers} from "../../../api/user";
import Action from "../../atoms/table/action";
import Pagination from "../../atoms/table/pagination";
import {useGetQueryString} from "../../../utils/useGetQueryString";
import {alertState, closeAlert, showAlert} from "../../../recoil/alert";
import {useSetRecoilState} from "recoil";
import {useQueryClient} from "react-query";

export const UserTemplate = () => {
    const {data: users, isLoading} = useFindUsers();
    const navigate = useNavigate();
    const {mutate} = useDeleteUser();
    const {page} = useGetQueryString();
    const setAlert = useSetRecoilState(alertState);
    const queryClient = useQueryClient();

    const goAdd = useCallback(() => {
        navigate('/user/add');
    }, []);

    const goEdit = useCallback((seq: string) => {
        navigate(`/user/edit/${seq}`);
    }, []);

    const onDelete = useCallback((seq: string) => {
        showAlert(setAlert, {title: '정말 삭제하시겠습니까?', onSubmit: () =>
                mutate({seq}, {
                    onSuccess: () => {
                        queryClient.invalidateQueries(['user']);
                        closeAlert(setAlert);
                    }
                }),
        })
    }, []);

    const columns = useMemo(
        () => [
            {header: '상태', accessor: 'useYn', width: '5%'},
            {header: '아이디', accessor: 'id', width: '30%'},
            {header: '닉네임', accessor: 'name', width: '20%'},
            {header: '권한', accessor: 'role', width: '20%'},
            {header: '가입일', accessor: 'insertDate', width: '10%'},
            {header: '수정일', accessor: 'updateDate', width: '10%'},
            {header: '', accessor: 'action', width: '5%'},
        ], []);

    const data = useMemo(() => users?.data && users?.data.length > 0 ? users?.data.map((x: userTypes) => {
        return {
            ...x,
            useYn: x.useYn === 0 ? <Tag theme={'red'}>탈퇴</Tag> : <Tag theme={'navy'}>정상</Tag>,
            insertDate: x.insertDate?.slice(0, 10),
            updateDate: x.updateDate?.slice(0, 10),
            action: Action({seq: x.seq || '', goEdit, onDelete}),
        };
    }) : '', [users]);

    return (
        <LayoutRightTemplate title={'유저 관리'} clickTitle={'등록'} onClick={goAdd}>
            <Table columns={columns} data={data} isLoading={isLoading}/>
            <Pagination count={users?.count || 1} page={Number(page)}/>
        </LayoutRightTemplate>
    );
};
