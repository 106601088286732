import {EditInviteTemplate} from "../../components/templates/invite/edit";
import LayoutTemplate from "../../components/templates/layout/left";

export const EditInvite = () => {
    return (
        <LayoutTemplate>
            <EditInviteTemplate />
        </LayoutTemplate>
    );
};
