import {AddContactTemplate} from "../../components/templates/contact/add";
import LayoutTemplate from "../../components/templates/layout/left";

export const AddContact = () => {
    return (
        <LayoutTemplate>
            <AddContactTemplate />
        </LayoutTemplate>
    )
}
