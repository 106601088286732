import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import "../../assets/css/common.css";
import React, {useEffect} from "react";
import {Login} from "../login";
import {useLoginCheck} from "../../api/user";
import {AddUser} from "../user/add";
import {User} from "../user";
import {EditUser} from "../user/edit";
import {useSetRecoilState} from "recoil";
import {setUser, userState} from "../../recoil/user";
import {Contact} from "../contact";
import {AddContact} from "../contact/add";
import {EditContact} from "../contact/edit";
import {Invite} from "../invite";
import {AddInvite} from "../invite/add";
import {EditInvite} from "../invite/edit";
import {Register} from "../register";
import {AddRegister} from "../register/add";
import {EditRegister} from "../register/edit";

export const App = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const {mutate} = useLoginCheck();
    const userStateHook = useSetRecoilState(userState);

    useEffect(() => {
        if (location.pathname === '/login') return;

        mutate(undefined, {
            onSuccess: async ({data}) => {
                setUser(userStateHook, {id: data.id, name: data.name, role: data.role});
            },
            onError: async () => {
                if (location.pathname !== '/login') navigate('/login');
            }
        });
    }, [location]);

    return (
        <Routes>
            <Route path="/login" element={<Login/>}/>

            <Route path="/user" element={<User/>}/>
            <Route path="/user/add" element={<AddUser/>}/>
            <Route path="/user/edit/:seq" element={<EditUser/>}/>

            <Route path="/contact" element={<Contact/>}/>
            <Route path="/contact/add" element={<AddContact/>}/>
            <Route path="/contact/edit/:seq" element={<EditContact/>}/>

            <Route path="/invite" element={<Invite/>}/>
            <Route path="/invite/add" element={<AddInvite/>}/>
            <Route path="/invite/edit/:seq" element={<EditInvite/>}/>

            <Route path="/register" element={<Register/>}/>
            <Route path="/register/add" element={<AddRegister/>}/>
            <Route path="/register/edit/:seq" element={<EditRegister/>}/>
        </Routes>
    );
}
