import styled from 'styled-components';
import {useRecoilValue, useSetRecoilState} from "recoil";
import {ReactNode, useCallback, useEffect, useState} from "react";
import {closeModal, modalState} from "../../../recoil/modal";

const Modal = ({title, children}: { title?: string, children?: ReactNode }) => {
    const setModal = useSetRecoilState(modalState);
    const [delayShow, setDelayShow] = useState(false);
    const {show, title: stateTitle, maxWidth: stateMaxWidth, children: stateChildren} = useRecoilValue(modalState);

    useEffect(() => {
        const el = document.querySelector('body');
        if (el) el.style.overflow = show ? 'hidden' : '';
    }, [show]);

    useEffect(() => {
        const timeout = show ? setTimeout(() => setDelayShow(true), 100) : undefined;

        return () => {
            setDelayShow(false);
            clearTimeout(timeout);
        }
    }, [show]);

    const onClose = useCallback(() => {
        setDelayShow(false);
        const timeout = setTimeout(() => closeModal(setModal), 300);

        return () => {
            setDelayShow(false);
            closeModal(setModal);
            clearTimeout(timeout);
        }
    }, []);

    if (show) return (
        <ModalWrap className={delayShow ? 'show' : ''}>
            <ModalInnerWrap $maxWidth={stateMaxWidth} className={delayShow ? 'show' : ''}>
                <ModalHeaderWrap>
                    <h2>{title && title}</h2>
                    <h2>{stateTitle && stateTitle}</h2>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                         stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"
                         onClick={onClose}
                    >
                        <path d="M18 6 6 18"></path>
                        <path d="m6 6 12 12"></path>
                    </svg>
                </ModalHeaderWrap>
                {stateChildren && stateChildren}
                {children && children}
            </ModalInnerWrap>
        </ModalWrap>
    );
}

const ModalWrap = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6);
  
  transition: visibility 0s linear 0.2s, opacity 0.2s 0s;
  visibility: hidden;
  opacity: 0;
  
  &.show {
    transition: visibility 0s ease-in-out 0s, opacity 0.4s ease-in-out 0s;
    visibility: visible;
    opacity: 1;
  }

  &.show > div {
    margin: 7.5vh auto auto auto;
    //transform: translateY(0);
  }
  
  padding: 0 40px;
`;

const ModalInnerWrap = styled.div<{$maxWidth?: string}>`
  width: 100%;
  max-width: ${({$maxWidth}) => $maxWidth ? $maxWidth : '800px'};
  max-height: 85vh;
  background-color: #fff;
  border-radius: 4px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  gap: 20px;

  position: relative;

  & > div > svg {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
  }
  
  margin: 0 auto auto auto;
  //transform: translateY(-10vh);
  transition: all ease-in-out 0.2s;
`

const ModalHeaderWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

export default Modal;
